<template>
  <div id="product">
    <!-- pc端 -->
    <div v-if="!_isMobile()">
      <div class="header_center">
        <div class="center1" />
        <ul>
          <li v-for="(item, i) in list" :key="i" @click="Link(i)">
            <img :src="item.img" class="img" />
            <div class="name">{{ item.name }}</div>
            <div class="brief">{{ item.brief }}</div>
          </li>
        </ul>
      </div>
    </div>
    <MyFooter class="footer" v-if="!_isMobile()" />
    <!-- 移动端 -->
    <div class="moblie-box" v-if="_isMobile()">
      <img src="@/assets/moblie/bg_08.png" class="top-img" />
      <ul>
        <li v-for="(item, i) in list" :key="i" @click="Link(i)">
          <div style="display: flex; justify-content: center">
            <img :src="item.img" class="img" />
          </div>
          <div class="name" style="text-align: center">{{ item.name }}</div>
          <div class="brief">{{ item.brief }}</div>
        </li>
      </ul>
    </div>
    <MyMoblieFooter class="footer" v-if="_isMobile()" />
  </div>
</template>

<script>
export default {
  name: 'Product',
  data () {
    return {
      list: [
        {
          img: require('@/assets/product/image1.png'),
          name: '向知大师课',
          brief:
            '“万花筒”课程体系，以通识力即⼈的内核能力为培养目标，将顶尖华⼈学者的认知框架、问题意识与学术成果，以形象直观的⽅式呈现，引导学生感受学科经典与学术前沿'
        },
        {
          img: require('@/assets/product/image2.png'),
          name: '光谱计划 · 图谱教学系统',
          brief:
            '基于「因材施教」理念，提供基于知识点为单元的“教、学、练、考、评、管”⼀站式数字化教学平台（KBL），实现贯通“教&学”全周期、全过程的数据可视化'
        },
        {
          img: require('@/assets/product/image3.png'),
          name: '与光同程 · 就业导航系统',
          brief:
            '基于专业培养及岗位胜任力视角下 PBL 训练营的职业导航体系。帮助学生明确职业⽅向；协助学校提升学生就业效率与就业质量；助力⽤⼈单位定制化⼈才培养与⼈才储备，提⾼⼈才招聘精准度'
        }
      ]
    }
  },
  methods: {
    Link (val) {
      if (val === 0) {
        this.$router.push('/service')
      } else if (!this._isMobile()) {
        if (val === 1) {
          window.open('https://www.lumispectrum.com')
        } else if (val === 2) {
          window.open('https://lumile.cn')
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
#product {
  .header_center {
    height: 100%;
    margin-bottom: 140px;
    .center1 {
      width: 100%;
      height: 560px;
      background: url("~@/assets/product/product_bg.png") no-repeat center /
        cover;
      margin-bottom: 40px;
    }
  }
  ul {
    display: flex;
    justify-content: center;
    li {
      width: 254px;
      height: 334px;
      color: #333333;
      background: #ffffff;
      border-radius: 20px;
      list-style: none;
      padding: 31px 18px;
      text-align: justify;
      cursor: pointer;
      .img {
        width: 104px;
        height: 104px;
        border-radius: 50%;
      }
      .name {
        font-size: 16px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        margin: 24px 0 15px;
      }
      .brief {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
      }
      &:hover {
        box-shadow: 0px 4px 17px 0px #e4f0fa;
      }
      &:nth-child(2) {
        margin: 0 35px;
      }
    }
  }
  .moblie-box {
    .top-img {
      width: 100%;
      height: auto;
    }
    ul {
      display: block;
      li {
        width: 296px;
        height: 262px;
        margin: 0 auto;
        box-shadow: 0px 4px 17px 0px #e4f0fa;
        &:nth-child(2) {
          margin: 14px auto;
        }
        &:nth-child(3) {
          margin: 0 auto 14px;
        }
      }
    }
  }
}
</style>
